import React, { useState } from "react";
import {
  XMarkIcon,
  PlayIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { useLocalStorage } from "@lib/hooks/use-local-storage";
import { Modal } from "@ui";
import { usePostHog } from "posthog-js/react";

type Props = {
  id: string;
  name: string;
  videoUrl: string;
};

const CollapsibleBanner = ({ id, name, videoUrl }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lastDismissed, setLastDismissed] = useLocalStorage<string>(
    `tracify:education-banner-${id}`,
    { usingJson: false }
  );
  const [isCollapsedState, setIsCollapsedState] = useLocalStorage<boolean>(
    `tracify:education-banner-collapsed-${id}`,
    { usingJson: true }
  );
  const posthog = usePostHog();

  // Convert YouTube URL to embed URL
  const getEmbedUrl = (url: string) => {
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      return `https://www.youtube.com/embed/${match[2]}?autoplay=1&modestbranding=1`;
    }

    return url;
  };

  const isVisible = !lastDismissed;

  const handlePermanentDismiss = () => {
    setLastDismissed(new Date().toISOString());
    posthog.capture("Educational banner dismissed");
  };

  const handleCollapse = () => {
    setIsCollapsedState(true);
    posthog.capture("Educational banner collapsed");
  };

  const handleExpand = () => {
    setIsCollapsedState(false);
    posthog.capture("Educational banner expanded");
  };

  const handleVideoClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsModalOpen(true);
    posthog.capture("Educational banner video clicked");
  };

  if (!isVisible) return null;

  return (
    <>
      <div
        className={`w-full transition-all duration-300 ease-in-out overflow-hidden relative rounded-md mt-2 ${
          isCollapsedState ? "h-[60px]" : "h-[200px]"
        }`}
        style={{
          background:
            "linear-gradient(90deg, #4D4799 0%, #8176FF 50%, #4D4799 100%)",
        }}
      >
        <div className="max-w-7xl mx-auto px-4 py-4">
          <div className="flex flex-col items-center text-center">
            <h2 className="text-xl font-semibold text-white">
              Get to know the {name}
            </h2>
            {!isCollapsedState && (
              <div className="mt-6 space-y-4">
                <p className="text-white/90 max-w-2xl">
                  We put various guides together to help you make the most out
                  of the {name}.
                </p>
                <button
                  onClick={handleVideoClick}
                  className="inline-flex bg-white text-primary font-bold px-4 py-2 rounded-md items-center gap-1 hover:bg-white/90 transition-colors"
                >
                  <PlayIcon className="h-6 w-6" />
                  Watch video
                </button>
              </div>
            )}
          </div>

          <div className="absolute right-4 top-4">
            {isCollapsedState ? (
              <button
                onClick={handleExpand}
                className="text-white hover:bg-white/10 p-2 rounded-full transition-all duration-500"
              >
                <ChevronDownIcon className="w-4 h-4" />
              </button>
            ) : (
              <DropdownMenu>
                <DropdownMenuTrigger className="text-white hover:bg-white/10 p-2 rounded-full transition-colors">
                  <XMarkIcon className="w-4 h-4" />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-56">
                  <DropdownMenuItem onClick={handleCollapse}>
                    Collapse for now
                  </DropdownMenuItem>
                  <DropdownMenuItem onClick={handlePermanentDismiss}>
                    Don&apos;t show this again
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        width="large"
        className="max-h-full"
      >
        <div
          className="relative w-full max-w-full overflow-hidden max-h-full"
          style={{ paddingTop: "56.25%" }}
        >
          <iframe
            src={getEmbedUrl(videoUrl)}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="absolute inset-0 w-full h-full !important aspect-video"
            title={`${name} Video Guide`}
          />
        </div>
      </Modal>
    </>
  );
};

export default CollapsibleBanner;
